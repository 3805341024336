import Calendar from "./Components/Calendar";
import { useState, useEffect, Fragment } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import UserMenu from "./Components/DropdownProfile";
import DayCard from "./Components/DayCard";
import Dialog from "./Components/Dialog";
import Login from "./Pages/Login";
import AdminCalendar from "./Pages/AdminCalendar";
import { useGetUser } from "./Hooks";
import CalendarEventProvider from "./Context/CalendarEventContext";
import ToastNotficationProvider from "./Context/ToastNotificationContext";
import { Outlet, NavLink } from "react-router-dom";
import PublicSettingsProvider from "Context/PublicSettingsContext";
import { Server } from "Utils/config";
import BackupProvider, { useBackupContext } from "Context/BackupContext";
import JSZip from "jszip";
import { BackupDialogButton } from "Components/BackupDialog";
import BackupMenuContainer from "Components/BackupMenu";
import CalendarProvider from "Context/CalendarContext";
import MailchimpEmailProvider from "Context/MailchimpEmailContext";

function App({ user, dispatch }) {

	const { backupMode } = useBackupContext();

	let backupHoverClass = backupMode ? 'bg-green-700' : 'bg-blue-500';

	return (
		<Fragment>
			<div className="flex h-screen w-screen">
				<div className={`${backupMode ? 'bg-green-700': 'bg-blue-500'} w-72`}>
					<div className="text-2xl text-center text-white p-4">Jarige Job</div>
					<div className="p-4 font-bold text-white">
						<ul>
							<li>
								<NavLink to="/" className={({isActive, isPending}) => `block px-4 py-2 hover:${backupHoverClass} ${isActive ? (backupMode ? 'bg-green-900' : 'bg-blue-700') : ''}`}>Overzicht</NavLink>
							</li>
							<li>
								<NavLink to="/aanvragen"  className={({isActive, isPending}) => `block px-4 py-2 hover:${backupHoverClass} ${isActive ? (backupMode ? 'bg-green-900' : 'bg-blue-700') : ''}`}>Aanvragen</NavLink>
							</li>
							<li>
								<NavLink to="/emails"  className={({isActive, isPending}) => `block px-4 py-2 hover:${backupHoverClass} ${isActive ? (backupMode ? 'bg-green-900' : 'bg-blue-700') : ''}`}>Verzonden E-mails</NavLink>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex-1 flex flex-col relative overflow-y-scroll">
					<div className="w-full sitcky top-0 bg-white border-b border-b-gray-300 flex justify-end">
						<div className="p-4">
							<UserMenu align="right" dispatch={dispatch} user={user} />
						</div>
					</div>
					<div>
						<CalendarEventProvider>
							<PublicSettingsProvider>
								<MailchimpEmailProvider>
									<Outlet />
									<BackupMenuContainer />
									<BackupDialogButton />
								</MailchimpEmailProvider>
							</PublicSettingsProvider>
						</CalendarEventProvider>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default CompleteApp;

function CompleteApp({ children }) {

	const [{ user }, dispatch] = useGetUser();

	console.log("user",user);

	if(!user) {

		return <div>
			<Login dispatch={dispatch} />
		</div>
	}


	return <ToastNotficationProvider>
		<CalendarProvider>
			<BackupProvider>
				<App user={user} dispatch={dispatch} />
			</BackupProvider>
		</CalendarProvider>
	</ToastNotficationProvider>

}


function readFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const content = e.target.result;
			resolve(content);
		}
		reader.readAsText(file);
	})

}
